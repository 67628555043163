/* |\\\\\\ variables |\\\\\\  */
// colors
$primaryColor: #76bf72;
$primaryDarkColor: #1a6731;
$primaryDarkerColor: #1b7f38;
$primarylightColor: #b8d761;
$fadePrimaryColor: #eef7ee;
$greenLight: #e4f7ea;
$buttonColor: #76bf72;
$buttonColorHover: #75a872;
$titleColor: #4fae38;
$orangeColor: #f06022;
$orangeLightColor: #ff7a00;
$alertColor: #ff000d;
$bgProductHover: #fdd9ee;
$counterErrorColor: #f55f07;
$GreyColor: #929292;
$fadeGreyColor: #ededed;
$textColor: #333333;
$popinProductTab: #eae5e1;
$accountBackgroundColor: #f5f5f5;
$accountLinkColor: #daf5d9;
$fadeGreenColor: rgba(118, 191, 114, 0.2);
$whiteColor: #ffffff;
$blackColor: #000000;
$bgColor: #fcf6ff;
$purple: #e4d1ee;
$lightWheat: #fbf4e9;
$blueColor: #1877f2;
$accountBorderColor: #dccfbe;
$borderColor: #dccfbe;

//font-family
$titlesFont: "GoodHeadlinePro";
$primaryFont: "Chivo-Regular";
$primaryFontBold: "Chivo-Bold";
$primaryFontLight: "Chivo-Light";
$secondaryFont: "OpenSans";
$defaultFont: "Vinyl OT";
$entryTitleFont: "RocaOne-Rg";
$secondaryEntryTitleFont: "Inter";
$interFont: "Inter";

//font size
$bigSize: 30px;
$smallSize: 12px;
$mediumSize: 17px;
$size11: 11px;
$size13: 13px;
$size14: 14px;
$size15: 15px;
$size16: 16px;
$size18: 18px;
$size19: 19px;
$size21: 21px;
$size20: 20px;
$size22: 22px;
$size24: 24px;
$size26: 26px;
$size28: 28px;
$size36: 36px;
$mediumLineHeight: 17px;

//Widths
$boxedLayout: 980px;
